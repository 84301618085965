type LabelType = string;
export type FormItemOption = { label: LabelType; value: any; description?: string };
export const optionFactory = (label: LabelType, value: any, description?: string): FormItemOption => ({
  label,
  value,
  description,
});

export const optionFactorySingle = (label: LabelType): FormItemOption => optionFactory(label, label);
export const optionsYesNo = (): FormItemOption[] => [optionFactory("Sim", "yes"), optionFactory("Não", "no")];
