import { FormValidatorFunction } from "@/interfaces/validation";

const validateNonEmptyValue: FormValidatorFunction<string | number | Array<any>> = (v) => {
  const type = typeof v;
  let resp = "Campo obrigatório";
  if (type === "string" && v !== "") {
    resp = undefined;
    // @ts-expect-error type ja e o valor certo do typeof ali em cima
  } else if (type === "number" && v > 0) {
    resp = undefined;
  } else if (Array.isArray(v) && v.length > 0) {
    resp = undefined;
  }

  return resp;
};

export default validateNonEmptyValue;
