<template>
  <InputText
    v-model="mutableModel"
    type="text"
    class="w-full"
    v-maska
    :data-maska="props.mask"
    :inputId="props.id"
    :name="props.name"
    :class="props.classes"
    @update:model-value="emit.update"
  />
</template>

<script setup lang="ts">
import { formItemEmits, formRenderEmits, formRenderEvents, formRenderProps } from "@components/FormItem/formItemHelper";
import { defineEmits, ref } from "vue";

const emiter = defineEmits(formRenderEvents);
const emit = formRenderEmits(emiter);
const props = defineProps(formRenderProps());
const mutableModel = ref(props.value);
</script>

<style lang="scss" scoped></style>
