<template>
  <InputText
    v-model="mutableModel"
    :type="props.type"
    :placeholder="props.placeholder"
    :inputId="props.id"
    :name="props.name"
    :class="props.classes"
    @update:model-value="handleValueChange"
  />
</template>

<script setup lang="ts">
import { formItemEmits, formRenderEmits, formRenderEvents, formRenderProps } from "@components/FormItem/formItemHelper";
import { defineEmits, ref } from "vue";

const emiter = defineEmits(formRenderEvents);
const emit = formRenderEmits(emiter);
const props = defineProps(formRenderProps());
const mutableModel = ref(props.value);

const handleValueChange = (v) => {
  emit.update(v);
};
</script>

<style lang="scss" scoped></style>
