<template>
  <Calendar
    v-model="mutableModel"
    dateFormat="dd/mm/yy"
    class="w-full h-full shadow-none"
    :class="props.classes"
    :inputId="props.id"
    :selectOtherMonths="true"
    :minDate="minDate"
    :on-blur="blur"
    append-to="self"
    @click="clickCalendar"
    @month-change="minDateStyle"
  />
</template>

<script setup lang="ts">
/**
 * Copiado e adaptado a partir do componente InputBox
 * @see InputBox
 * */

import { formRenderEmits, formRenderEvents, formRenderProps } from "@components/FormItem/formItemHelper";
import { DateMonthNamesLongToIndex } from "@enums/DateEnum";
import { defineEmits, nextTick, ref, watch } from "vue";

import UserFormBuilder from "@/services/ResultsService/UserFormBuilder";

const minDate = new UserFormBuilder().generateMinDate();

const emiter = defineEmits(formRenderEvents);
const emit = formRenderEmits(emiter);
const props = defineProps(formRenderProps());
const mutableModel = ref(props.value);

const minDateStyle = () => {
  const currentMonth = new Date().getMonth() + 1;

  nextTick(() => {
    const calendar = document.querySelector(".p-datepicker-calendar");
    const minDateStyle = calendar.querySelectorAll(
      `td[aria-label="${minDate.getDate()}"]:not(.p-datepicker-other-month) span`,
    );

    const currentVisibleMonth = document
      .querySelector("[data-pc-section='monthtitle']")
      .textContent.trim()
      .toLocaleLowerCase();
    if (currentMonth === DateMonthNamesLongToIndex[currentVisibleMonth]) {
      minDateStyle[0].classList.add("min-date--selected");
    }
  });
};

const clickCalendar = () => {
  nextTick(() => {
    minDateStyle();
  });
};

const blur = () => undefined; // emit("blur");

watch(() => mutableModel.value, emit.update);
</script>

<style lang="scss" scoped></style>
