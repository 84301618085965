import axios from "axios";

import Fetcher from "@/services/Fetcher/Fetcher";
import Network from "@/services/Fetcher/Network";

export type ActivePromoResponse = {
  id: number;
  description: string;
  coupon: string;
  condition: string;
  active: true;
  date_start: string;
  date_end: string;
  created_at: string;
  updated_at: string;
}[];

type HomeFormData = {
  name: string;
  phone: string;
  email: string;
  cnpj: string;
  budget: string;
  city: string;
  state: string;
  verticals: string[];
  verticals_suggest: boolean;
  contact_type: string;
  company?: string;
  agency?: string;
  client?: string;
};

export const sendHSHomeForm = async (formData: HomeFormData): Promise<string> => {
  const portalId = "5803045";
  const formId = "25dccb0d-a847-4b48-af8c-1e842c1d6a5e";
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const namePieces = formData.name.split(" ");
  const fName = namePieces.shift();
  const lName = namePieces.join(" ");

  const payload = {
    fields: [
      createFieldEntry("firstname", fName),
      createFieldEntry("lastname", lName),
      createFieldEntry("phone", formData.phone),
      createFieldEntry("email", formData.email),
      createFieldEntry("cnpj___anunciante", formData.cnpj),
      createFieldEntry("0-2/name", formData.company ?? "nao informado"),
      createFieldEntry("city", formData.city),
      createFieldEntry("state", formData.state),
      createFieldEntry("investimento_valor_detalhado", formData.budget),
      createFieldEntry("verticais_de_interesse", formData.verticals.join(",") ?? "nao informado"),
      createFieldEntry(
        "sugerir_verticais_de_interesse",
        formData.verticals_suggest ? "sugerir ambientes" : "nao sugerir ambientes",
      ),
      createFieldEntry("preferencia_contato", formData.contact_type),
    ],
  };

  const response = await axios(url, {
    data: payload,
    method: Network.METHOD_POST,
    headers: {
      [Network.Header.ContentType]: Network.APPLICATION_JSON,
    },
  });

  return response.data.inlineMessage;
};

const createFieldEntry = (name: string, value: string) => ({
  objectTypeId: "0-1",
  name,
  value,
});
