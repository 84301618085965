import { FormValidatorFunction } from "@/interfaces/validation";
import phoneValidator from "@/utils/validatePhone";

const validateFullName: FormValidatorFunction<string> = (v) => {
  const pieces = v.trim().split(" ");
  if (pieces.length >= 2) {
    return;
  }

  return "Nome completo";
};

export default validateFullName;
