<template>
  <div class="grid gap-4" :class="[`sm:grid-cols-${grid.sm}`, `md:grid-cols-${grid.md}`, `lg:grid-cols-${grid.lg}`]">
    <VerticalCategoryButton
      v-for="verticalEntry in props.options"
      :key="verticalEntry.code"
      :type="verticalEntry.code"
      :selected="isCategorySelected(verticalEntry.code)"
      :click="handleCategoryChange"
    />
  </div>
</template>

<script setup>
import { formRenderEmits, formRenderEvents, formRenderProps } from "@components/FormItem/formItemHelper";
import VerticalCategoryButton from "@components/VerticalCategoryButton/VerticalCategoryButton.vue";
import { computed, defineEmits, ref } from "vue";

const emiter = defineEmits(formRenderEvents);
const emit = formRenderEmits(emiter);
const props = defineProps(formRenderProps());
const mutableModel = ref(props.value);

const grid = computed(() => {
  const [sm = 2, md = 3, lg = 5] = props.gridSize.split(" ");
  return { sm, md, lg };
});

const isCategorySelected = (code) => {
  const selected = mutableModel.value?.find((cat) => cat.code === code) !== undefined;
  return selected;
};

const handleCategoryChange = (cat) => {
  const cats = [...(mutableModel.value ?? [])];
  const code = cat.code;
  const catIndex = cats.findIndex((c) => c.code === code);
  const isSelected = catIndex !== -1;

  if (isSelected && cats.length > 1) {
    cats.splice(catIndex, 1);
  } else {
    cats.push(cat);
  }

  mutableModel.value = cats;
  emit.update(cats);
};
</script>

<style lang="scss" scoped></style>
