import { FormValidatorFunction } from "@/interfaces/validation";
import cnpjValidator from "@/utils/validateCNPJ";

const validateCNPJ: FormValidatorFunction<string> = (v) => {
  if (cnpjValidator(v)) {
    return;
  }

  return "CNPJ inválido";
};

export default validateCNPJ;
