import { FormValidatorFunction } from "@/interfaces/validation";
import phoneValidator from "@/utils/validatePhone";

const validatePhone: FormValidatorFunction<string> = (v) => {
  if (phoneValidator(v, false)) {
    return;
  }

  return "Telefone inválido";
};

export default validatePhone;
